body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ReviewsLanding {
    padding: 20px;
}

/*@media (max-width: 640px) {
    .ReviewsLanding {
        padding: 10px;
    }

        .ReviewsLanding h1,
        .ReviewsLanding h2,
        .ReviewsLanding input,
        .ReviewsLanding textarea,
        .ReviewsLanding p,
        .ReviewsLanding span {
            text-align: center;
        }

        .ReviewsLanding h1 {
            font-size: 1.5rem;
        }

        .ReviewsLanding h2 {
            font-size: 1.2rem;
        }

        .ReviewsLanding input,
        .ReviewsLanding textarea {
            width: 100%;
            padding: 10px;
            font-size: 1rem;
        }

        .ReviewsLanding .flex {
            flex-direction: column;
        }

            .ReviewsLanding .flex .w-1/2 {
                width: 100%;
                margin: 0;
            }

            .ReviewsLanding .flex .mr-2,
            .ReviewsLanding .flex .ml-2 {
                margin: 0;
            }

        .ReviewsLanding .mt-4 {
            margin-top: 1rem;
        }

        .ReviewsLanding .text-xs {
            font-size: 0.75rem;
        }

        .ReviewsLanding .text-m {
            font-size: 1rem;
        }

        .ReviewsLanding .text-3xl {
            font-size: 2rem;
        }
}*/

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
